import { User, Shop } from '../types';
import { LangDictKey, TextOnly } from '../components/Text';
import { SHOP_ROLES } from '../CONSTANTS';
import { getUserRecord, getVINDetails } from './db-lib';
import { standardizeShopType } from './utils';
import { isMoment } from 'moment';

export function getAuditLogLangPhraseId(engString: string, isReinstatingOems: boolean | undefined, isAddingOems: boolean | undefined) {
  const phraseIdStringPairs: { [key: string]: string } = {
    'User created their account': 'userCreatedTheirAcc',
    'User confirmed their account': 'userConfirmedTheirAcc',
    'User imported a set of new users': 'userImportedNewUsers',
    'User performed a bulk invite for a set of users to join a shop': 'userBulkInvitedUsers',
    'User revoked an invite for a user to join a shop': 'userRevokedInvite',
    'User updated their info': 'userUpdatedInfo',
    'User created a shop': 'userCreatedShop',
    "User updated a shop's info": 'userUpdatedShop',
    "User updated a shop's payment info": 'userUpdatedPaymentInfo',
    "User removed a shop's payment info": 'userRemovedPaymentInfo',
    "User updated a shop's max user capacity": 'userUpdatedMaxCap',
    'User added a user to a shop': 'userAddedUser',
    'User removed a user from a shop': 'userRemovedUser',
    'User responded to a shop invitation': 'userRespondedToInvite',
    'User changed a users state at a shop': 'userChangedUserState',
    'User registered a tool for a shop': 'userRegisteredTool',
    'User removed a tool from a shop': 'userRemovedTool',
    'User imported a set of tools into a shop': 'userImportedTools',
    'User logged in via a tool': 'userLoggedInTool',
    'User performed an ADInitiate via a tool': 'userADInitiateTool',
    'User performed an ADRequest via a tool': 'userADRequestTool',
    'User performed an ADExecute via a tool': 'userADExecuteTool',
    'User created a signup link for users to join shop': 'userCreatedSignupLink',
    'User updated a signup link': 'userUpdatedSignupLink',
    'User got an error on ADInitiate via a tool': 'userErrorADInitiateTool',
    'User got an error on ADRequest via a tool': 'userErrorADRequestTool',
    'User got an error on ADExecute via a tool': 'userErrorADExecuteTool',
    'Shop subscription state was changed by an automated process': 'shopSubscriptionStateChanged',
    "User upgraded shop type to PLUS": 'userUpgradedToPlus',
    'User repayed canceled payment to reinstate shop': 'userRepayedCanceledPayment',
    'User marked shop for downgrade to STANDARD': 'userMarkedShopForDowngrade',
    'User canceled shop downgrade to STANDARD': 'userCanceledDowngradeShop',
    "User reinstated shop's OEM/Regions marked for removal at next renewal date.": 'userReinstatedShopOems',
    "User updated a shop's OEM/Regions list": 'userUpdatedShopOems',
    "User removed an OEM from a shop": 'userRemovedShopOems',
    "User's Tech Certification Status has changed": 'userBecameCertifiedTech',
    "User updated a shop's max tech certifications capacity": 'userUpdatedShopTechCertCapacity',
    "User edited a tool for a shop": 'userEditedToolForShop',
    'User initiated a tool recovery': 'userInitiatedToolRecovery',
    'User created a new customer': 'userCreatedCRMCustomer',
    'User updated a customer': 'userUpdatedCRMCustomer',
    'User created a new event': 'userCreatedCRMEvent',
    'User updated an event': 'userUpdatedCRMEvent',
    'User deleted an event': 'userDeletedCRMEvent',
    'User created a new vehicle': 'userCreatesCRMVehicle',
    'User removed a vehicle': 'userRemovedCRMVehicle',
    'User updated a vehicle': 'userUpdatedCRMVehicle',
    'User created a new order': 'userCreatedCRMOrder',
    'User updated an order': 'userUpdatedCRMOrder',
    'User assigned a new technician to an order': 'userAssignedNewTechnicianCRMOrder',
  };

  if (engString === "User reinstated shop's OEM/Regions marked for removal at next renewal date.") {
    if (isReinstatingOems && isAddingOems) {
      return TextOnly('userAddedAndReinstatedOems' as LangDictKey);
    } else if (isReinstatingOems) {
      return TextOnly('userReinstatedOems' as LangDictKey);
    } else if (isAddingOems) {
      return TextOnly('userAddedOems' as LangDictKey);
    }
  }

  return TextOnly(phraseIdStringPairs[engString] as LangDictKey);
}

export function getFirstShop(shops: Shop[]): Shop | null {
  // only one shop
  if (shops.length === 1) {
    return shops[0];
  }

  // first OWNER shop
  const ownerShops = shops.filter(
    (shop) => shop.shopUserRole === SHOP_ROLES.OWNER
  );

  if (ownerShops.length > 0) {
    return ownerShops[0];
  }

  // first ADMIN shop
  const adminShops = shops.filter(
    (shop) => shop.shopUserRole === SHOP_ROLES.ADMIN
  );

  if (adminShops.length > 0) {
    return adminShops[0];
  }

  // first TECH shop
  if (shops.length > 1) {
    return shops[0];
  }

  return null;
}

export function getSavedShop(shops: Shop[]): Shop | null {
  const firstShop = getFirstShop(shops);

  const savedShopId = window.localStorage.getItem('CURRENT_SHOP');
  if (!savedShopId && firstShop) {
    window.localStorage.setItem('CURRENT_SHOP', firstShop.shopID);
  }

  const savedShop = findShopById(shops, savedShopId || '');

  return savedShop || firstShop;
}

export function findShopById(shops: Shop[], shopId: string): Shop | null {
  return shops?.find((shop) => shop.shopID === shopId) || null;
}

export function getSavedColumns(name: string): Record<string, number> {
  return JSON.parse(localStorage.getItem(name) || '[]').reduce(
    (acc: Record<string, string>, column: { id: string; value: number }) => ({
      ...acc,
      [column.id]: column.value,
    }),
    {}
  );
}

export function findUserById(
  userId: string,
  shopUsers: User[]
): User | undefined {
  return shopUsers?.find((user) => user.userID === userId);
}

export function getUserName(userId: string, shopUsers: User[]): string {
  const user = findUserById(userId, shopUsers);
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
}

export async function getUserData(): Promise<{
  user?: User;
  dataRetrieveError?: string;
}> {
  const user = await getUserRecord();
  const userRec = user && user.data ? user.data : null;

  if (userRec) {
    const shops = userRec.userShops;
    const shopsNormalized = shops.map((shop: Shop) => ({
      ...shop,
      shopType: standardizeShopType(shop.shopType),
    }));

    // find first best shop for user
    const currentShop = getSavedShop(shopsNormalized);

    const shopTools = currentShop?.userShopTools?.length
      ? currentShop?.userShopTools
      : [];
    const shopRole = currentShop?.shopUserRole || SHOP_ROLES.TECH;
    const shopState = currentShop?.shopUserState || '';
    const shopType = currentShop?.shopType || '';
    const shopMaxUsers = currentShop?.shopMaxUsers || 0;
    const shopUserCap = currentShop?.shopUserCap;
    const shopToolCap = currentShop?.shopToolCap;
    const shopPaymentInfo = currentShop?.shopPaymentInfo || {};
    const paymentState = currentShop?.shopSubscriptionState || '';

    return {
      user: {
        firstName: userRec.firstName,
        lastName: userRec.lastName,
        email: userRec.email,
        userName: userRec.userName,
        username: userRec.userName,
        mbRole: userRec.application?.MESSAGE_BOARD?.mbRole,
        name: `${userRec.firstName} ${userRec.lastName}`,
        language:
          userRec.language === 'undefined'
            ? window.localStorage.getItem('rcml-lang')
            : userRec.language,
        userID: userRec.userID,
        role: userRec.shopRole,
        shopRole: shopRole, // remove
        userRole: shopRole, // remove
        shopUserRole: shopRole, // remove
        shopState: shopState, // remove
        shopMaxUsers: shopMaxUsers, // remove
        shopUserCap: shopUserCap,
        shopToolCap: shopToolCap,
        shopPaymentInfo: shopPaymentInfo, // remove
        paymentState: paymentState,
        shops: shopsNormalized,
        shopTools: shopTools,
        shopUserState: shopState,
        addedOn: userRec.shopAddedOn,
        addedBy: userRec.shopAddedBy,
        userShops: userRec.userShops,
        shopAddedBy: userRec.shopAddedBy,
        shopAddedOn: userRec.shopAddedOn,
        userState: userRec.userState,
        ...userRec,
      },
    };
  } else {
    return {
      dataRetrieveError: user?.error
        ? user.error
        : 'Unknown data retrieval error. Maybe login token expired. Please login again.',
    };
  }
}

export function capitalize(word: string): string {
  if (word.length === 0) {
    return word;
  }
  const loweredCase = word.toLowerCase();
  return word[0].toUpperCase() + loweredCase.slice(1);
}

export const capitalizeFirstLetter = (word: string) => {
  return word.length === 0
    ? word
    : word[0].toUpperCase() + word.slice(1);
}

export function trimObjFields(obj: Record<string, any>): any {
  // If the value of this object is an Array, Date, or Moment, do not convert it
  // Additionally, trim array fields
  if (obj) {
    if (obj.constructor === Array) {
      let trimmedArray = obj as Array<any>;
      return trimmedArray.map((element) => {
        return trimObjFields(element);
      });
    } else if (
      obj.constructor === Date ||
      isMoment(obj) ||
      typeof obj === 'string'
    ) {
      return obj;
    }
  }

  let newObj = { ...obj };
  for (const key in newObj) {
    if (typeof newObj[key] === 'string') {
      newObj[key] = newObj[key].trim();
    }
    // recursive into objects
    if (typeof newObj[key] === 'object') {
      newObj[key] = trimObjFields(newObj[key]);
    }
  }
  return newObj;
}

export function getBraintreeConfig(mode: string) {
  return {
    authorization:
      mode === "sandbox"
        ? "sandbox_ybtknhk8_gzyk4gttyg2qk4rs"
        : "production_zjzmxrjs_twfvg2vqvm88m54n",
    container: "#braintree-container",
    locale: TextOnly("locale"),
    card: {
      overrides: {
        styles: {
          input: {
            "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
            color: "#556066",
          },
        },
        fields: {
          postalCode: {
            placeholder: TextOnly('postalCodeBraintree')
          }
        }
      },
    },
  }
}

export async function getVINDetailsInChunks(shopID: string, vins: string[]) {
  // Initialize map to handle new vin mappings, set() to temp store vins to process, and get local cache for vin mappings
  let vinMap: { [k: string]: any } = {};
  let vinsSet  = new Set<string>();
  let vinCache = JSON.parse(localStorage.getItem('vinCache') as string) || {};

  vins.forEach((vin: string) => {
    if (vin && !vinCache.hasOwnProperty(vin)) {
      vinsSet.add(vin);
    }
  });

  // Array-ify the set
  let vinArray = Array.from(vinsSet.values());

  // If we have no array elements, return the cache and skip fetching
  if (!vinArray.length) {
    return vinCache;
  }
  let vinObjects = [];

  // Split the list up and then merge the results
  for (let i = 0; i < vinArray.length; i += 100) {
    const chunk = vinArray.slice(i, i + 100);
    let vinInfo = await getVINDetails(shopID, chunk);
    vinObjects.push(vinInfo);
  }
  let vinFlatten = vinObjects.flat();

  for (let i = 0; i < vinArray.length; i++) {
    // If we filter and the vinFlatten array of objects does not have the vinArray value, then we will push the new key value pair into vinFlatten as an invalid VIN.
    if (vinFlatten.filter(obj => obj.vin === vinArray[i]).length === 0) {
      vinFlatten.push({vin: vinArray[i]})
    }
  }

  // Assign mappings of vin detail object to vin number
  vinFlatten.forEach((obj: any) => {
    vinMap[obj.vin] = {
      make: obj.make || 'The make could not be determined',
      model: obj.model || 'The model could not be determined',
      year: obj.year || 'The year could not be determined',
      trim: obj.trim || 'The trim could not be determined',
    };
    // Cache in localStorage
    localStorage.setItem(
      'vinCache',
      JSON.stringify(Object.assign(vinCache, vinMap))
    );
  });

  // Return our cached object with the new vinMap appended
  return Object.assign(vinCache, vinMap);
}


