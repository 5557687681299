import React, { useState, useRef } from 'react';
import _ from 'underscore';
import { ChildProps } from '../../types';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import countries from 'i18n-iso-countries';
import { Text, TextOnly } from '../../components/Text';
import LoaderButton from '../../components/LoaderButton';
import CountryPicker from '../../components/CountryPicker';
import StatePicker from '../../components/StatePicker';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { toast } from 'react-toastify';
import {
	CountryCode,
  E164Number,
} from 'libphonenumber-js/core';
import {
  updateShopInfo,
  logSmartyStreets,
} from '../../libs/db-lib';
import {
  nameValidate,
} from '../../libs/utils';

const ShopInfo = (props: ChildProps) => {

  const {
    currentShop,
    config,
    fetchUser,
  } = props;

  const typeaheadRef = useRef<any>(null)

  const [streetAddress1, setStreetAddress1] = useState(currentShop.streetAddress1 || '');
  const [streetAddress2, setStreetAddress2] = useState(currentShop.streetAddress2 || '');
  const [city, setCity] = useState(currentShop.city || '');
  const [state, setState] = useState(currentShop.state || '');
  const [zip, setZip] = useState(currentShop.zip || '');
  const [shareInfo, setShareInfo] = useState(currentShop.shareInfo || false);
  const [country, setCountry] = useState(currentShop.country || '');
  const [shopPhoneNumber, setShopPhoneNumber] = useState(currentShop.phone || '');
  const [isLoadingShopName, setIsLoadingShopName] = useState(false);
  const [shopName, setShopName] = useState(currentShop.shopName);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [typeaheadOpen, setTypeaheadOpen] = useState<boolean | undefined>();

  const handleEditShop = async () => {
    let shopInfo = {
      streetAddress1: streetAddress1,
      streetAddress2: streetAddress2,
      city: city,
      state: state,
      zip: zip,
      country: country,
      phone: shopPhoneNumber,
      shareInfo: shareInfo,
    };

    let selectedAddress: any = {};
    Object.assign(selectedAddress, shopInfo);
    delete selectedAddress.phone;

    setIsLoadingShopName(true);

    let result = await updateShopInfo(
      currentShop.shopID,
      shopName,
      false,
      shopInfo
    );
    if (result?.error) {
      toast.error(`${TextOnly('error')}: ${result.error}`, {
        containerId: 'standard',
      });
      setIsLoadingShopName(false);
      await logSmartyStreets(
        { success: true },
        selectedAddress,
      );
    } else {
      toast.success(TextOnly('shopUpdated'), { containerId: 'standard' });
      fetchUser();
      setIsLoadingShopName(false);
    }
  };

  const validateShopFields = (event: any) => {
    event.preventDefault();

    if (
      streetAddress1 !== currentShop.streetAddress1 ||
      streetAddress2 !== currentShop.streetAddress2 ||
      city !== currentShop.city ||
      state !== currentShop.state ||
      zip !== currentShop.zip ||
      shopPhoneNumber !== currentShop.phone ||
      shareInfo !== currentShop.shareInfo ||
      shopName !== currentShop.shopName
    ) {
      handleEditShop();
    }
  };

  const handleChange = (event: any) => {
    let newValue = event.target.value;
    if (event.target.id === 'country') {
      setAddressSuggestions([]);
      setCountry(event.target.value);
      setStreetAddress1('');
      setStreetAddress2('');
      setCity('');
      setState('');
      setZip('');

      typeaheadRef?.current?._instance?.clear();
      if (newValue === 'US') {
        setState(state?.toUpperCase());
      }
    } else if (event.target.id === 'shopName') {
      setShopName(event.target.value);
    } else if (event.target.id === 'streetAddress2') {
      setStreetAddress2(event.target.value);
    } else if (event.target.id === 'city') {
      setCity(event.target.value);
    } else if (event.target.id === 'state') {
      setState(event.target.value);
    } else if (event.target.id === 'zip') {
      setZip(event.target.value);
    }
  };

  const handleChangePhoneNumber = (number: E164Number) => {
    let newNumber = number ? number : '';
    setShopPhoneNumber(newNumber)
  }


  const validateFreeformAddress = async (query: any) => {
    const smartyStreetKey = config.smartyStreets.key;
    if(country !== 'US') {
      let url = `https://international-autocomplete.api.smarty.com/v2/`;
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += `&country=${countries.alpha2ToAlpha3(country)}`;
      url += '&license=international-autocomplete-v2-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          setAddressSuggestions(response.candidates || [])
          return response;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
        return false;
      }
    } else {
      let url = `https://us-autocomplete-pro.api.smartystreets.com/`
      url += `lookup?key=${smartyStreetKey}`;
      url += `&search=${query}`;
      url += '&license=us-autocomplete-pro-cloud';
      try {
        const result = await fetch(url);
        if (!result.ok) {
          throw new Error(`HTTP error! Status: ${result.status}`);
        } else {
          const response = await result.json();
          setAddressSuggestions(response.suggestions || []);
          return response;
        }
      } catch (error: any) {
        console.error('Error:', error.message);
        return false;
      }
    }
  };

  const debounceAddressSearch = _.debounce((query: any) => {
    validateFreeformAddress(query);
  }, 500);

  const handleSearchAddress = (query: any) => {
    if (query.trim().length === 0) {
      setAddressSuggestions([]);
    }
    setStreetAddress1(query);

    debounceAddressSearch(query);
  };

  const getAddressInfo = async (option: any) => {
    const smartyStreetKey = config.smartyStreets.key;
    let url = `https://international-autocomplete.api.smarty.com/v2/`;
    url += `lookup/${option.address_id}`;
    url += `?key=${smartyStreetKey}`;
    url += `&country=${countries.alpha2ToAlpha3(country)}`;
    url += `&license=international-autocomplete-v2-cloud`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        const jsonResponse = await response.json();
        const result = jsonResponse.candidates;
        return result;
      }
    } catch (error: any) {
      console.error('Error:', error.message);
      return false;
    }
  };

  const profileFieldsHaveChanged = () => {
    let shopNameValid = nameValidate(shopName);
    let addressValid = streetAddress1?.length > 0;
    let cityValid = city?.length > 0;
    let stateValid = state?.length > 0;
    let zipValid = zip?.length > 0;
    let countryValid = country?.length > 0;
    let phoneValid = shopPhoneNumber?.length > 0;
    return (
      shopNameValid &&
      addressValid &&
      cityValid &&
      stateValid &&
      zipValid &&
      countryValid &&
      phoneValid &&
      (
        shopName !== currentShop.shopName ||
        streetAddress1 !== currentShop.streetAddress1 ||
        streetAddress2 !== currentShop.streetAddress2 ||
        city !== currentShop.city ||
        state !== currentShop.state ||
        country !== currentShop.country ||
        zip !== currentShop.zip ||
        shopPhoneNumber !== currentShop?.phone ||
        shareInfo !== currentShop.shareInfo
      )
    );
  }

  const isInternational = country !== 'US';

  return (
    <form onSubmit={validateShopFields}>
      <div className="u-height-min-800 l-container-sm l-container-flex-box">
        <div className="c-field">
          <label htmlFor="shopName" className="c-field__label">
            <span className="u-text-error">*</span>{' '}
            <Text tid="shopName" />
          </label>
          <input
            id="shopName"
            type="text"
            maxLength={50}
            className={
              !nameValidate(shopName)
                ? 'c-input__error'
                : 'c-input'
            }
            placeholder={TextOnly('shopName')}
            value={shopName}
            onChange={handleChange}
          />
          {!nameValidate(shopName) ? (
            <div className="c-field__error">
              <Text tid="shopNameHint" />
            </div>
          ) : null}
        </div>
        <>
          <CountryPicker
            country={country}
            countryVerify={true}
            handleChange={handleChange}
          />
          <div className={`c-field`}>
            <label
              htmlFor="streetAddress1"
              className="c-field__label"
            >
              <span className="u-text-error">*</span>{' '}
              <Text tid="streetAddress1" />
            </label>
            <AsyncTypeahead
              ref={typeaheadRef}
              filterBy={() => true}
              id="streetAddress1"
              inputProps={{
                id: 'streetAddress1',
                className:
                  streetAddress1?.length > 0
                    ? 'c-input'
                    : 'c-input__error',
              }}
              defaultInputValue={streetAddress1}
              isLoading={false}
              flip={false}
              clearButton
              minLength={1}
              labelKey={(option: any) =>
                isInternational
                  ? `${streetAddress1 || option.address_text}`
                  : `${option.street_line}`
              }
              maxResults={100}
              onSearch={handleSearchAddress}
              onChange={async (option: any) => {
                option = option[0];
                //User is deleting/backspacing or clicked clearButton
                if (!option || option.length === 0) {
                  setAddressSuggestions([]);
                  setStreetAddress1('');
                  setStreetAddress2('');
                  setCity('');
                  setState('');
                  setZip('');
                  return;
                }

                if (isInternational) {
                  const result = await getAddressInfo(option);
                  if(result) {
                    if(result.length > 1) {
                      setTypeaheadOpen(true);
                      setAddressSuggestions(result || []);
                      setStreetAddress2('');
                      setCity('');
                      setState('');
                      setZip('');
                    } else {
                      setTypeaheadOpen(false);
                      setStreetAddress1(result[0].street);
                      setStreetAddress2('');
                      setCity(result[0].locality);
                      setState(result[0].administrative_area || result[0].locality);
                      setZip(result[0].postal_code);
                    }
                  }
                } else {
                  let needsExpansion =
                    option.secondary && option.entries > 1;
                  if (needsExpansion) {
                    setTypeaheadOpen(true);
                    setStreetAddress1(option.street_line);
                    setStreetAddress2('');
                    setCity(option.city);
                    setState(option.state);
                    setZip(option.zipcode);
                    let selectedQuery = `${encodeURIComponent(streetAddress1)}&selected=${encodeURIComponent(`${option.street_line} ${option.secondary} (${option.entries}) ${option.city} ${option.state} ${option.zipcode}`)}`;
                    handleSearchAddress(selectedQuery);
                  } else {
                    setTypeaheadOpen(undefined);
                    setStreetAddress1(option.street_line);
                    setStreetAddress2(option.secondary || '');
                    setCity(option.city);
                    setState(option.state);
                    setZip(option.zipcode);
                  }
                }
              }}
              options={addressSuggestions}
              paginate={false}
              open={
                addressSuggestions.length === 0
                  ? false
                  : typeaheadOpen
              }
              placeholder={TextOnly('streetAddress1')}
              renderMenuItemChildren={(option: any, props) => {
                if (isInternational) {
                  return (
                    <div className="typeahead-menu-item-children">
                      {option.address_text}
                    </div>
                  );
                }

                let secondaryStr: any =
                  option.secondary.length !== 0
                    ? `, ${option.secondary}`
                    : '';
                let needsExpansion =
                  option.secondary && option.entries > 1;
                if (needsExpansion) {
                  secondaryStr = (
                    <span>
                      , {option.secondary} #
                      <b>
                        {'('}
                        {option.entries} results{')'}
                      </b>
                    </span>
                  );
                }
                return (
                  <div className="typeahead-menu-item-children">
                    {option.street_line}
                    {secondaryStr}, {option.city} {option.state},{' '}
                    {option.zipcode}
                  </div>
                );
              }}
            />  {/* AsyncTypeahead */}
            {streetAddress1?.trim().length === 0 ? (
              <p className="u-text-error">
                <Text tid="fieldCannotBeBlank" />
              </p>
            ) : null}
          </div>
          <div className={`c-field`}>
            <label
              htmlFor="streetAddress2"
              className="c-field__label"
            >
              <Text tid="streetAddress2" />:
            </label>
            <input
              id="streetAddress2"
              type="text"
              maxLength={50}
              className="c-input"
              placeholder={TextOnly('streetAddress2')}
              value={streetAddress2}
              onChange={handleChange}
            />
          </div>
          <div className={`c-field`}>
            <label htmlFor="city" className="c-field__label">
              <span className="u-text-error">*</span>{' '}
              <Text tid="city" />
            </label>
            <input
              id="city"
              type="text"
              maxLength={50}
              className={
                city?.length > 0
                  ? 'c-input'
                  : 'c-input__error'
              }
              placeholder={TextOnly('cityTown')}
              value={city}
              onChange={handleChange}
            />
            {city?.trim().length === 0 ? (
              <p className="u-text-error">
                <Text tid="fieldCannotBeBlank" />
              </p>
            ) : null}
          </div>
          <div className="l-flex-wrap l-flex-gap">
            {country === 'US' ? (
              <StatePicker
                state={state || ''}
                handleChange={handleChange}
              />
            ) : (
              <div className={`c-field l-container-xs`}>
                <label htmlFor="state" className="c-field__label">
                  <span className="u-text-error">*</span>{' '}
                  <Text tid="state" />
                </label>
                <input
                  id="state"
                  type="text"
                  maxLength={50}
                  className={`u-input-side-by-side c-input${
                    state?.trim().length === 0
                      ? '__error'
                      : ''
                  }`}
                  placeholder={TextOnly('state')}
                  value={state}
                  onChange={handleChange}
                />
                {state?.trim().length === 0 ? (
                  <p className="u-text-error">
                    <Text tid="fieldCannotBeBlank" />
                  </p>
                ) : null}
              </div>
            )}

            <div className={`c-field l-container-xs`}>
              <label htmlFor="zip" className="c-field__label">
                <span className="u-text-error">*</span>{' '}
                <Text tid="zipCode" />
              </label>
              <input
                id="zip"
                type="text"
                maxLength={50}
                className={`u-input-side-by-side c-input${
                  zip?.trim().length === 0 ? '__error' : ''
                }`}
                placeholder={TextOnly('zipCode')}
                value={zip}
                onChange={handleChange}
              />
              {zip?.trim().length === 0 ? (
                <p className="u-text-error">
                  <Text tid="fieldCannotBeBlank" />
                </p>
              ) : null}
            </div>
          </div>
          <div className={`c-field`}>
            <label htmlFor="phone" className="c-field__label">
              <span className="u-text-error">*</span>{' '}
              <Text tid="phoneNumber" />
            </label>
            <PhoneInput
              id="phone"
              type="tel"
              maxLength={50}
              className={`c-input ${
                !isPossiblePhoneNumber(shopPhoneNumber)
                  ? "phoneError"
                  : ""
              }`}
              value={shopPhoneNumber}
              defaultCountry={country as CountryCode}
              onChange={handleChangePhoneNumber}
            />
          </div>
          <div className={`c-field`}>
            <div className="l-flex-wrap u-align-center">
              <div><Text tid='shareInfo'/>:</div>
              <div className={"l-inline-flex"}>
                <input
                  id="shareInfoYes"
                  type="radio"
                  name="shareInfo"
                  checked={shareInfo}
                  onChange={()=>setShareInfo(!shareInfo)}
                  className="u-margin-right-large"
                />
                <label
                  className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
                >
                  <Text tid="yes" />
                </label>
                <input
                  id="shareInfoNo"
                  type="radio"
                  name="shareInfo"
                  checked={!shareInfo}
                  onChange={()=>setShareInfo(!shareInfo)}
                  className="u-margin-right-large"
                />
                <label
                  htmlFor="shareInfoNo"
                  className="c-field__label left-padding u-padding-bottom-none"
                >
                  <Text tid="no" />
                </label>
              </div>
            </div>
          </div>
        </>
        <div className="c-field">
          <LoaderButton
            type="submit"
            disabled={
              !profileFieldsHaveChanged() ||
              !nameValidate(shopName)
            }
            isLoading={isLoadingShopName}
            text={TextOnly('saveChanges')}
            loadingText={TextOnly('savingChanges')}
          />
        </div>
      </div>
    </form>
  );

}

export default ShopInfo;