import React, { useEffect, useRef, useState } from 'react';
import { TextOnly, Text } from '../../../components/Text';
import LoaderButton from '../../../components/LoaderButton';
import { toast } from 'react-toastify';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import BasicDatePicker from '../../../components/BasicDatePicker';
import { crmCreateShopEvent, crmUpdateShopEvent, crmDeleteShopEvent } from '../../../libs/db-lib';
import BasicTimePicker from '../../../components/BasicTimePicker';
import { EVENT_TIMEOFF_TYPE_OBJ, EVENT_TYPE } from '../CrmConstants';
import HighlighterColorComp from '../InnerComponents/HighlighterColorComp';
import { convertOrderNumToDisplay, formatOrderRelatedEvents } from '../../../libs/utils';
import AddEditOrderForm from '../ManageOrders/AddEditOrderForm';
import ConfirmModal from '../../../components/ConfirmModal';


const AddEditEventForm: React.FC<any> = (props) => {
  const {
    currentShop,
    onSubmit,
    handleCloseModal,
    isLoading,
    setIsLoading,
    eventData,
    shopUserList,
    shopOrderList,
    isAppointment,
    shopCustomerList,
    setNewEventInfo,
    user,
  } = props;

  const shopTechTypeahead = useRef<any>();
  const shopOrderTypeahead = useRef<any>();

  const shopTechnicians = shopUserList
  ?.filter((tech: any) => tech.shopUserState === 'ACTIVE')
  .map((technicianAux: any)=>{
    const technician: { id: string, label: string } = {
      id: technicianAux.userID,
      label: `${technicianAux?.firstName} ${technicianAux?.lastName} (${technicianAux?.userName})`,
    };
    return technician;
  });

  const shopOrders = shopOrderList?.map((order: any) => {
    const displayOrderNo = convertOrderNumToDisplay(order.orderNumber);
    const displayOrder = {
      id: order.orderID,
      label: `${TextOnly('order')}#${displayOrderNo}: ${order.title}`
    };
    return displayOrder;
  }).sort((a: any, b: any) => {
    const labelA = a.label.toLowerCase();
    const labelB = b.label.toLowerCase();
    // sorting descending order by order number
    return (
      labelA > labelB ? -1
      : labelA < labelB ? 1
      : 0
    );
  });
  shopOrders?.unshift({
    id: 'newOrder',
    label: `+ ${TextOnly('createNewOrder')}`
  });

  let startDate = '';
  let startTime = '';
  let endDate = '';
  let endTime = '';
  let eventStartHour = '';
  let eventStartMinute = '';
  let eventEndHour = '';
  let eventEndMinute = '';
  let minStartTime = '';

  if (eventData) {
    // eventData.startStr ~ 2024-12-01T05:00:00-06:00
    [startDate, startTime] = eventData.startDate?.split('T');
    [eventStartHour, eventStartMinute] = startTime?.split(':') ?? [undefined , undefined];
    minStartTime = startTime;

    [endDate, endTime] = eventData?.endDate?.split('T') ?? [undefined , undefined];
    if (endDate) {
      [eventEndHour, eventEndMinute] = endTime?.split(':') ?? [undefined , undefined];
    }
  }

  const [eventTitle, setEventTitle] = useState<string>(eventData?.title ?? (!isAppointment ? EVENT_TYPE.VACATION : ''));
  const [description, setDescription] = useState<string>(eventData?.description ?? '');
  const [eventStartDate, setEventStartDate] = useState<string>(startDate);
  const [eventEndDate, setEventEndDate] = useState<string>(endDate);
  const [eventStartTime, setEventStartTime] = useState<string>((!eventStartHour && !eventStartMinute) ? '' : `${eventStartHour}:${eventStartMinute}`);
  const [eventEndTime, setEventEndTime] = useState<string>((!eventEndHour && !eventEndMinute) ? '' : `${eventEndHour}:${eventEndMinute}`);
  const [minSelectableTime, setMinSelectableTime] = useState<string>(minStartTime);
  const [highlighterColor, setHighlighterColor] = useState<string>(eventData?.highlighterColor ?? 'BLUE');
  const [isAllDay, setIsAllDay] = useState<boolean>(eventData?.allDay ?? !isAppointment);
  const [isOpenTypeaheadTech, setIsOpenTypeaheadTech] = useState<boolean>(false);
  const [isOpenTypeaheadOrder, setIsOpenTypeaheadOrder] = useState<boolean>(false);
  const [selectedTechnician, setSelectedTechnician] = useState<any>(eventData?.technician?.userID);
  const [orderInputValue, setOrderInputValue] = useState<string>('');
  const [selectedOrder, setSelectedOrder] = useState<any>(
    eventData?.orderID ?
      shopOrders.find((order: any) => order.id === eventData.orderID) ?
        [
          shopOrders.find((order: any) => order.id === eventData.orderID)
        ]
        : []
      : []);
  const [selectedOrderData, setSelectedOrderData] = useState<any>()
  const [newOrderInfo, setNewOrderInfo] = useState<any>(null);
  const [showChangesHint, setShowChangesHint] = useState<boolean>(!eventData ? false : true);
  const [eventType, setEventType] = useState<string>(eventData?.eventType ?? (isAppointment ? EVENT_TYPE.APPOINTMENT : EVENT_TYPE.VACATION));
  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);

  const handleRemoveEvent = async () => {
    const response = await crmDeleteShopEvent(currentShop.shopID, eventData.eventID)
    if (!response.error) {
      await onSubmit?.();
      toast.success(TextOnly(isAppointment ? 'appointmentRemoved' : 'timeOffRemoved'),
        { containerId: 'standard' }
      );
      return response;
    } else {
      toast.error(response.error, {
        containerId: 'standard',
      });
      return response;
    }
  };

  const handleCancelConfirmModal = (removedSuccess?: boolean) => {
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      setShowRemoveModal(false);
      if (removedSuccess) handleCloseModal()
    }, 350);
  };

  const validateSubmit = () => {
    const isValidOrder = selectedOrder[0]?.id === 'newOrder'
      ? newOrderInfo?.validateSubmit()
      : !!selectedOrder[0]?.id;
    const hasRequiredDateFields = isAllDay
      ? !!eventEndDate
      : eventStartTime && eventEndTime;

    // Making sure all values are valid
    if (
      eventStartDate &&
      highlighterColor &&
      hasRequiredDateFields &&
      // If this component is within create order, none of the following fields are required
      (setNewEventInfo || (
        eventTitle.trim().length &&
        // Event type specific requirements:
        (eventType === EVENT_TYPE.APPOINTMENT ?
          isValidOrder
        :
          selectedTechnician
        )
      ))
    ) {
      // Making sure that at least one attribute changed
      if (
        !!eventData?.eventID &&
        eventData.allDay === isAllDay &&
        eventData.title === eventTitle &&
        eventData.description === description &&
        eventData.highlighterColor === highlighterColor &&
        eventData.technician?.userID === selectedTechnician &&
        ( !eventData.orderID ? true :
          eventData.orderID === selectedOrder[0]?.id
        ) &&
        eventData.eventType === eventType &&
        eventData.startDate.includes(eventStartDate) &&
        (isAllDay ?
          eventData.endDate.includes(eventEndDate)
        :
          eventData.startDate.includes(eventStartTime) &&
          eventData.endDate.includes(eventEndTime)
        )
      ) {
        // Nothing has changed
        if (!showChangesHint) setShowChangesHint(true);
        return false;
      } else {
        if (showChangesHint) setShowChangesHint(false);
        return true;
      }
    } else {
      //At least one value is not valid
      return false;
    }
  };

  const handleSubmit = async () => {
    if (validateSubmit()) {
      setIsLoading(true);

      const response: any = !eventData?.eventID ? await crmCreateShopEvent(
        eventType,
        currentShop.shopID, //shopID
        eventType !== EVENT_TYPE.APPOINTMENT ? selectedTechnician : '', //userID
        eventType === EVENT_TYPE.APPOINTMENT
          ? (newOrderInfo ?? { orderID: selectedOrder[0].id })
          : null,
        eventTitle, //title
        isAllDay, //allDay
        description,
        eventStartDate,
        eventEndDate,
        eventStartTime,
        eventEndTime,
        highlighterColor,
      ) : await crmUpdateShopEvent(
        eventType,
        currentShop.shopID,
        eventData?.eventID,
        eventType !== EVENT_TYPE.APPOINTMENT ? selectedTechnician : '', //userID
        eventType === EVENT_TYPE.APPOINTMENT
          ? (newOrderInfo ?? { orderID: selectedOrder[0].id })
          : null,
        eventTitle, //title
        isAllDay, //allDay
        description,
        eventStartDate,
        eventEndDate,
        eventStartTime,
        eventEndTime,
        highlighterColor,
      )
      setIsLoading(false);

      if (!response.error) {
        toast.success(TextOnly('success'), { containerId: 'standard' });
        handleCloseModal();
        await onSubmit?.();
      } else {
        toast.error(`${TextOnly('error')}: ${response.message ?? response.error}`, { containerId: 'standard' });
      }
    }
  };

  useEffect(()=>{
    if(selectedOrder[0]?.id) {
      const order = shopOrderList.find((order: any) => order.orderID === selectedOrder[0].id)
      setSelectedOrderData(order)
    };
  }, [selectedOrder]);

  useEffect(()=>{
    setMinSelectableTime(eventStartTime);
  }, [eventStartTime])

  useEffect(()=>{
    if (isAllDay) {
      setEventStartTime('');
      setEventEndTime('');
    } else {
      setEventEndDate('');
    }
  },[isAllDay]);

  useEffect(() => {
    const fieldsAreValid = validateSubmit();
    if (fieldsAreValid && setNewEventInfo) {
      setNewEventInfo({
        startDate: eventStartDate,
        endDate: eventEndDate,
        startTime: eventStartTime,
        endTime: eventEndTime,
        highlighterColor,
        allDay: isAllDay,
      });
    }
  }, [
    eventStartDate,
    highlighterColor,
    eventEndDate,
    eventStartTime,
    eventEndTime,
    isAllDay,
  ]);


  return (
    <div className={setNewEventInfo ? 'l-container-med no-spacing' : 'l-container-med'}>
      <div className={setNewEventInfo ? '' : "u-margin-left u-margin-top u-padding-bottom-80"}>
        {eventData?.eventID &&
          <ConfirmModal
            className='c-modal'
            showConfirmModal={showRemoveModal}
            closeModal={handleCancelConfirmModal}
            onConfirm={()=>handleRemoveEvent()}
            textModal={TextOnly(isAppointment ? 'confirmRemoveAppointment' : 'confirmRemoveTimeOff')}
            titleModal={TextOnly(isAppointment ? 'removeAppointment' : 'removeTimeOff')}
            textConfirmButton={TextOnly('remove')}
            textLoadingConfirmButton={TextOnly('removing')}
          />
        }
        <div>
          { !isAppointment &&
            <div className="c-field u-margin-right-large max-width-fit-content">
              <label className={`c-field__label`}>
                <span className="u-text-error">*</span>{' '}
                <Text tid="type" />:
              </label>
                <div
                  className={`c-select`}
                >
                    <select
                      value={eventType}
                      onChange={(event) => {
                        const eventType = event?.target?.value;
                        const techName = selectedTechnician ?
                          `: ${shopTechnicians.find((tech: any) => tech.id === selectedTechnician)?.label}`
                          :
                          '';

                        setEventType(eventType);
                        setEventTitle(`${eventType} ${techName}`);
                      }}
                      disabled={isLoading || isAppointment}
                    >
                      <option value="" disabled>
                        {TextOnly('type')}
                      </option>
                      {
                        EVENT_TIMEOFF_TYPE_OBJ.map((status:any) => {
                          return (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                          )
                        })
                      }
                    </select>
                </div>
            </div>
          }
          <div className="flex-row u-width-100-percent">
            <div className={`c-field u-flex-expand ${setNewEventInfo ? 'hide-element' : ''}`}>
              <label className={`c-field__label`} htmlFor="title">
                {eventType === EVENT_TYPE.APPOINTMENT ? <span className="u-text-error">* </span> : null}{TextOnly('title')}:
              </label>
              <input
                type="text"
                id="title"
                className={`c-input ${(eventTitle.length > 0 && !eventTitle.trim().length) ? 'c-input__error' :''}`}
                placeholder={`${TextOnly('title')}`}
                value={eventTitle}
                onChange={(event) => setEventTitle(event.target.value)}
                disabled={isLoading || !isAppointment}
              />
            </div>
            <div className='u-width-fit-content'>
              <HighlighterColorComp
                isLoading={isLoading}
                highlighterColor={highlighterColor}
                setHighlighterColor={setHighlighterColor}
              />
            </div>
          </div>
        </div>

        <div className={`c-field ${setNewEventInfo ? 'hide-element' : ''}`}>
          <label className={`c-field__label`} htmlFor="description">
            {`${TextOnly('description')}`}:
          </label>
          <textarea
            className="c-input c-textarea"
            id="description"
            name="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            placeholder={`${TextOnly('description')}`}
            rows={2}
            cols={40}
            required
            disabled={isLoading}
          />
        </div>
        <div className='display-flex l-flex-column'>
          <label className={`c-field__label u-padding-left-5`}>
            <span className="u-text-error">*</span> {`${TextOnly('date')}`}:
          </label>
          <div className='display-flex flex-wrap-wrap'>
            <div className={`c-field no-space-left-right`}>
              <BasicDatePicker
                setStateVariable={setEventStartDate}
                stateVariable={eventStartDate}
                disabled={isLoading}
              />
            </div>
            { isAllDay ? (
              <>
                <div className='u-padding-top-10px u-padding-sides-xs'>
                  {TextOnly('to')}
                </div>
                <div className={`c-field no-space-left-right`}>
                  <BasicDatePicker
                    stateVariable={eventEndDate}
                    setStateVariable={setEventEndDate}
                    minSelectableDate={eventStartDate}
                    disabled={isLoading}
                  />
                </div>
              </>
            ) : (
              <div className='u-padding-top-10px u-padding-sides-xs'>
                {TextOnly('from')}
              </div>
            )}
            <div className='display-flex flex-wrap-wrap'>
            { !isAllDay && (
              <div className='display-flex'>
                <div className={`c-field no-space-left-right`}>
                  <BasicTimePicker
                    stateVariable={eventStartTime}
                    setStateVariable={setEventStartTime}
                    disabled={isLoading || isAllDay}
                  />
                </div>
                <div className='u-padding-top-10px u-padding-sides-xs'>
                  {TextOnly('to')}
                </div>
                <div className={`c-field no-space-left-right`}>
                  <BasicTimePicker
                    stateVariable={eventEndTime}
                    setStateVariable={setEventEndTime}
                    minSelectableTime={minSelectableTime}
                    isEndTime={!eventData?.eventID}
                    disabled={isLoading || isAllDay}
                  />
                </div>
              </div>
            )}
            <div className={`c-field align-end-centered u-padding-left-large`}>
              <div className={"l-inline-flex"}>
                <input
                  id="allDay"
                  type="checkbox"
                  checked={isAllDay}
                  onChange={()=>isAllDay ? setIsAllDay(false) : setIsAllDay(true)}
                  className="u-margin-right"
                  disabled={isLoading || !isAppointment}
                />
                <label
                  htmlFor='noAllDay'
                  className="c-field__label left-padding u-padding-bottom-none u-margin-right-large"
                >
                  <Text tid="allDayEvent" />
                </label>
              </div>
            </div>
          </div>
          </div>
        </div>
        { eventType !== EVENT_TYPE.APPOINTMENT ?
          <div className='c-field u-width-50-percent'>
            <label className={`c-field__label`} htmlFor="shopTechnician">
              <span className="u-text-error">*</span> {`${TextOnly('technician')}`}:
            </label>
            <Typeahead
              ref={shopTechTypeahead}
              id={'shopTechnician'}
              options={shopTechnicians}
              onChange={(technician:any) => {
                setSelectedTechnician(technician[0]?.id ?? null);
                if (technician[0]) {
                  setIsOpenTypeaheadTech(false);
                  setEventTitle(`${eventType}: ${technician[0].label}`);
                  setSelectedTechnician(technician[0].id);
                }
              }}
              placeholder={TextOnly('selectTechnician')}
              renderMenuItemChildren={(option: any, props: any, index) => {
                return (
                  <div className="typeahead-menu-item-children">
                    {option.label}
                  </div>
                )
              }}
              selected={
                (selectedTechnician) ?
                [shopTechnicians.find((tech: any) => tech.id === selectedTechnician)]
                : []
              }
              open={isOpenTypeaheadTech}
              onBlur={()=>{
                setIsOpenTypeaheadTech(false)
                if (!selectedTechnician) {
                  shopTechTypeahead.current.clear();
                }
              }}
              onFocus={()=>setIsOpenTypeaheadTech(true)}
              disabled={isLoading}
            />
          </div> :
          <div className={setNewEventInfo ? 'hide-element' : ''}>
            <h2>{TextOnly('orderInformation')}</h2>
            <div className='c-field'>
              <label className={`c-field__label`} htmlFor="shopOrder">
                <span className="u-text-error">* </span> {`${TextOnly('selectOrder')}`}:
              </label>
              <Typeahead
                ref={shopOrderTypeahead}
                id={'shopOrder'}
                options={shopOrders}
                selected={selectedOrder}
                open={isOpenTypeaheadOrder}
                onBlur={()=>{
                  if (!selectedOrder[0]) {
                    shopOrderTypeahead.current.clear();
                    setOrderInputValue('');
                  }
                  setIsOpenTypeaheadOrder(false)
                }}
                onFocus={()=>setIsOpenTypeaheadOrder(true)}
                onInputChange={(newInput) => setOrderInputValue(newInput)}
                onChange={(order:any) => {
                  setSelectedOrder(order);
                  if (order[0]) {
                    setIsOpenTypeaheadOrder(false);
                    setOrderInputValue('');
                    if (order[0]?.id !== 'newOrder') {
                      setNewOrderInfo(null);
                    }
                  }
                }}
                placeholder={TextOnly('selectOrder')}
                filterBy={(option: any) => {
                  if (option?.label?.toLowerCase().includes(orderInputValue?.toLowerCase())) {
                    return true;
                  }
                  return option?.label?.includes(TextOnly('createNewOrder'));
                }}
                renderMenuItemChildren={(option: any, props: any, index) => {
                  return (
                    <div className="typeahead-menu-item-children">
                      {option.label}
                    </div>
                  )
                }}
                disabled={isLoading}
              />
              {selectedOrder[0]?.id === 'newOrder' ?
                <AddEditOrderForm
                  currentShop={currentShop}
                  isLoading={isLoading}
                  setNewOrderInfo={setNewOrderInfo}
                  shopCustomers={shopCustomerList}
                  user={user}
                /> : (selectedOrder[0]?.id) ?
                (
                  <div className='u-margin-left-xlarge u-margin-top'>
                    <div className='white-space-nowrap'>
                      <i className="u-width-30px fa fa-car" aria-hidden="true" />&nbsp;
                      {selectedOrderData?.vehicle?.year} {selectedOrderData?.vehicle?.make} {selectedOrderData?.vehicle?.model}
                    </div>
                    <div className='white-space-nowrap'>
                      <i className="u-width-30px fa fa-user" aria-hidden="true" />&nbsp;
                      {selectedOrderData?.customer?.firstName} {selectedOrderData?.customer?.lastName}
                    </div>
                    <div className='white-space-nowrap'>
                      <i className="u-width-30px fa fa-wrench" aria-hidden="true" />&nbsp;
                        { selectedOrderData?.technicianID ?
                          `${selectedOrderData?.tech?.firstName} ${selectedOrderData?.tech?.lastName} (${selectedOrderData?.tech?.userName})`
                          : TextOnly('noTechnicianAssigned')
                        }
                    </div>
                    <div>
                      <label className={`c-field__label u-margin-top`}>
                        {`${TextOnly('relatedAppointments')}`}:
                      </label>
                      {
                        formatOrderRelatedEvents(selectedOrderData?.events?.filter((event: any)=>event.eventID !== eventData?.eventID))
                      }
                    </div>
                  </div>
                )
                :
                null
              }
            </div>
          </div>
        }

        <div className={setNewEventInfo ? "hide-element" : "u-margin-top-large c-field display-flex-justify-content-space-around align-items-center"}>
          <LoaderButton
            text={TextOnly(eventData?.eventID ? 'saveChanges' : isAppointment ? 'scheduleAppointment' : 'scheduleTimeOff')}
            onClick={() => handleSubmit()}
            isLoading={isLoading}
            loadingText={ TextOnly(eventData?.eventID ? 'savingChanges' : 'loading')}
            disabled={!validateSubmit()}
          />
          {eventData?.eventID &&
            <LoaderButton
              className="c-btn-outline"
              disabled={isLoading}
              text={TextOnly(isAppointment ? 'removeAppointment' : 'removeTimeOff')}
              onClick={() => setShowRemoveModal(true)}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default AddEditEventForm;
