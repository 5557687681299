import { Dialog } from '@reach/dialog';
import React, { FC } from 'react';
import { TextOnly } from '../Text';

interface ConfirmReadyForPickUpNotificationProps {
  isOpen: boolean;
  onSubmit: Function;
}

const ConfirmReadyForPickUpNotification: FC<ConfirmReadyForPickUpNotificationProps> = ({
  isOpen,
  onSubmit,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      className={ "c-modal send-notification-modal"}
      aria-label="ConfirmModal"
    >
      <h1 className='margin-2rem'>{TextOnly('sendCustomerNotification')}</h1>
      <h2 className="c-modal__heading">
        {TextOnly("confirmCustomerNotificationMessage")}
      </h2>
      <div className='display-flex-justify-content-center alignitems-baseline margin-16px'>
        <button
          className="c-btn"
          onClick={() => {
            onSubmit(true);
          }}
        >{TextOnly('send')}
        </button>
        {'\u00A0'}
        <button
          className="c-btn-outline"
          onClick={() => {
            onSubmit(false);
          }}
        >
          {TextOnly('dontSend')}
        </button>
      </div>
    </Dialog>
  );
};

export default ConfirmReadyForPickUpNotification;