import React, { Component } from 'react';
import {
  fetchAuthSession,
  signOut,
  signIn,
} from 'aws-amplify/auth';
import { Link, NavLink } from 'react-router-dom';
import { Dialog } from '@reach/dialog';
import LoaderButton from '../components/LoaderButton';
import { recordUserSession } from '../libs/db-lib';
import { Text, TextOnly } from '../components/Text';
import { fieldChangeCase, passwordValidate } from '../libs/utils';
import { ExternalFooterLinks } from '../components/ExternalFooterLinks';
import LanguageSelector from '../components/LanguageSelector';
import AlertModal from '../components/AlertModal';
import { toast } from 'react-toastify';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      newPassword: '',
      alertMessage: '',
      showModal: false,
      showAlertModal: false,
      requestNewPassword: false,
      language: null,
    };
  }

  componentDidMount() {
    // First check if we are verifying an email address
    if (this.props.verifyUserEmail) {
      this.props.history.push({
        pathname: '/verifyUserEmail',
        state: {
          code: this.props.code,
          username: this.props.username,
          clientId: this.props.clientId,
          email: this.props.email,
          language: this.props.language,
        },
      });
    } else {
      if (this.props.isAuthenticated) {
        this.props.history.push({
          pathname: '/myActivity',
        });
      }

      if (this.props?.location?.state?.previousToast) {
        this.setState({
          previousToast: this.props.location.state.previousToast,
        });
      }

      let username =
        this.props?.location?.state?.username ||
        document.getElementById('username') ||
        '';

      let alertMsg = this.props?.location?.state?.emailVerified || null;

      // Check the username and password fields to see is values have been autofilled
      if (username.length > 0) {
        this.setState({
          username: username,
        });
        if (alertMsg) {
          if (this.props?.location?.state?.success) {
            const toastId = toast.success(alertMsg, {
              autoClose: false,
              containerId: 'standard',
            });
            this.setState({ previousToast: toastId });
          } else {
            const toastId = toast.error(alertMsg, {
              autoClose: false,
              containerId: 'standard',
            });
            this.setState({ previousToast: toastId });
          }
        }
      }
      let password = document.getElementById('password');
      if (password.length > 0) {
        this.setState({
          password: password,
        });
      }
    }
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event) => {
    if (event.target.id === 'username') {
      fieldChangeCase(this, event.target, 'lower', false);
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handleNewPasswordChange = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  activateField = (event) => {
    this.setState({
      [event.target.id + 'FieldActivate']: true,
    });
  };
  disableField = (event) => {
    if (event.target.value === '') {
      this.setState({
        [event.target.id + 'FieldActivate']: false,
      });
    }
  };

  handleResetPassword = async (event) => {
    event.preventDefault();

    await fetchAuthSession()
      .then((user) => {
        signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push('/resetPassword');
      })
      .catch((err) => {
        this.props.history.push('/resetPassword');
      });
  };

  handleResendConfirm = async (event) => {
    event.preventDefault();

    await fetchAuthSession()
      .then((user) => {
        signOut();
        this.props.userHasAuthenticated(false);
        this.props.history.push('/resendConfirmCode');
      })
      .catch((err) => {
        this.props.history.push('/resendConfirmCode');
      });
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleAlertCancel = () => {
    this.setState({ showAlertModal: false });
  };

  handleCancelPasswordModal = () => {
    const self = this;
    document.querySelector('.c-modal-slider')?.classList.add('closed');
    setTimeout(() => {
      self.setState({ requestNewPassword: false, isLoading: false });
    }, 350);
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true, signingIn: true });

    if (this.state.previousToast) {
      toast.dismiss(this.state.previousToast);
    }

    try {
      const { nextStep } = await signIn({
        username: this.state.username,
        password: this.state.password
      });

      if (nextStep === 'NEW_PASSWORD_REQUIRED') {
        this.setState({ cognitoUserObj: user, requestNewPassword: true });
      } else {
        recordUserSession();
        this.props.userHasAuthenticated(true);
        this.setState({ signingIn: true });
        localStorage.removeItem('CURRENT_SHOP');

        // Check for redirect
        if (window.location.href.includes('?redirect=')) {
          let url = window.location.href.split('?redirect=');
          let redirectPage = url[1];
          this.props.history.push(redirectPage);
        } else {
          this.props.history.push('/myActivity');
        }
      }

    } catch (e) {
      console.log(JSON.stringify(e));
      let errMsg = e.message ? e.message : e;
      if (
        errMsg === 'User does not exist.' ||
        errMsg === 'Incorrect username or password.' ||
        e.name === 'InvalidParameterException'
      ) {
        errMsg = TextOnly('incorrectUserAndPass');
      } else if (errMsg === 'User is not confirmed.') {
        errMsg = TextOnly('userNotConfirmed');
      }
      const toastId = toast.error(errMsg, {
        autoClose: false,
        containerId: 'standard',
      });
      this.setState({
        previousToast: toastId,
        isLoading: false,
      });
    }
  };

  handleChangePasswordModal = async (event) => {

    await confirmSignIn({ challengeResponse: this.state.newPassword })
    .then(() => {
      recordUserSession();
      this.props.userHasAuthenticated(true);
      this.setState({ signingIn: true });
      this.props.history.push('/myActivity');
    })
    .catch((e) => {
      console.log(JSON.stringify(e));
      this.setState({ alertMessage: e.message, showModal: true });
    });
  };

  render() {
    return (
      <>
        <div className="l-container-lg l-full-height">
          <div className="l-form-column u-margin-top-large">
            <LanguageSelector />
          </div>
          <div className="l-form-column">
            <form onSubmit={this.handleSubmit}>
              <h2 className="u-text-center h1 u-margin-top-none">
                <Text tid="userLogin" />
              </h2>
              <div className="c-field">
                <input
                  id="username"
                  maxLength="100"
                  type="text"
                  className="c-input"
                  placeholder={TextOnly('usernameOrEmail')}
                  value={this.state.username}
                  onChange={this.handleChange}
                  autoCapitalize="off"
                />
              </div>
              <div className="c-field">
                <input
                  id="password"
                  maxLength="50"
                  type="password"
                  className="c-input"
                  placeholder={TextOnly('password')}
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </div>
              <div className="u-text-center u-margin-bottom-large u-padding-top-large">
                <Link
                  to="/resetPassword"
                  onClick={this.handleResetPassword.bind(this)}
                >
                  <Text tid="forgotPassword" />
                </Link>
              </div>

              <div className="u-text-center u-margin-bottom-large">
                <Link
                  to="/resendConfirmCode"
                  onClick={this.handleResendConfirm.bind(this)}
                >
                  <Text tid="resendConfirmLink" />
                </Link>
              </div>
              <LoaderButton
                className="c-btn-full"
                disabled={!this.validateForm()}
                type="submit"
                isLoading={this.state.isLoading}
                text={<Text tid="login" />}
                loadingText={<Text tid="loggingIn" />}
              />
            </form>
          </div>
          <div className="l-form-column">
            <h2 className="u-text-center h1 u-margin-top-none">
              <Text tid="needAnAccount" />
            </h2>
            <NavLink to="/signup" className="c-btn-full">
              <Text tid="register" />
            </NavLink>
          </div>
          <ExternalFooterLinks />
        </div>
        <Dialog
          isOpen={this.state.showModal}
          onDismiss={this.handleCancel.bind(this)}
          className="c-modal"
          aria-label={TextOnly('loginError')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancel.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="loginError" />
          </h1>

          <div className="c-modal__body">
            <p>{this.state.alertMessage}</p>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.requestNewPassword}
          onDismiss={this.handleCancelPasswordModal.bind(this)}
          className="c-modal-slider"
          aria-label={TextOnly('changePassword')}
        >
          <button
            className="c-btn-icon c-modal-slider__close"
            onClick={this.handleCancelPasswordModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="changePassword" />
          </h1>

          <div className="c-modal__body">
            <div className="c-field">
              <label htmlFor="enterNewPassword" className="c-field__label">
                <Text tid="enterNewPassword" />
              </label>
              <input
                maxLength="50"
                id="enterNewPassword"
                placeholder={TextOnly('enterNewPassword')}
                className="c-input"
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange.bind(this)}
                type="password"
              />
              {this.state.newPassword.length > 0 &&
              !passwordValidate(this.state.newPassword) ? (
                <div className="c-field__error">
                  <Text tid="passwordRequirements" />
                </div>
              ) : null}
            </div>
            <button
              className="c-btn"
              onClick={this.handleChangePasswordModal.bind(this)}
            >
              <Text tid="changePassword" />
            </button>{' '}
            <button
              className="c-btn-outline"
              onClick={this.handleCancelPasswordModal.bind(this)}
            >
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>

        <AlertModal
          message={this.state.alertMessage}
          showModal={this.state.showAlertModal}
          size="small"
          handleCancel={this.handleAlertCancel.bind(this)}
        />
      </>
    );
  }
}
