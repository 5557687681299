import React, { Component } from 'react';
import {
  sendUserAttributeVerificationCode,
  confirmUserAttribute,
 } from 'aws-amplify/auth';
import { Link } from 'react-router-dom';
import { LangDictKey, Text, TextOnly } from '../../components/Text';
import { respondToShopInvitation, removeUserFromShop, getOEMRegionList } from '../../libs/db-lib';
import AlertModal from '../../components/AlertModal';
import { Loading } from '../../components/Loading';
import { Dialog } from '@reach/dialog';
import Header from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';
import PromoBox from '../../components/PromoBox';
import { toast } from 'react-toastify';
import { PAYMENT_STATES, SHOP_ROLES, SHOP_STATES } from '../../CONSTANTS';
import { Led } from '../../components/Led';
import { UserContextInterface, useUser } from '../../context/User';
import { MyShopActionsTable } from './MyShopActionsTable';
import { MyToolActionsTable } from './MyToolActionsTable';
import { Shop, User, ChildProps } from '../../types';
import './MyActivity.css';
import { currentShopUsersFromProps, convertOrderNumToDisplay } from '../../libs/utils';
import EventsList from './EventsList';
import OrderStatus from './OrderStatus';
import config from '../../config';
import {
  crmGetShopEvents,
  crmGetShopOrders
} from '../../libs/db-lib';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import CrmPromoContent from '../Crm/Promo/CrmPromoContent';
import StatsAndTrendsPromo from '../StatsAndTrendsPromo';

interface HomeProps extends ChildProps {
  history: any;
  userContext: UserContextInterface;
}

type ActivityType = 'TOOL_ACTIONS' | 'SHOP_ACTIONS';
type CrmActivityType = 'APPOINTMENT_ACTIONS' | 'ORDER_ACTIONS';

interface HomeState {
  isLoading: boolean;
  showModal: boolean;
  emailVerificationCodeSent: boolean;
  windowWidth: number;
  rowsText: string;
  showConfirmModal: boolean;
  showPlusLearnMoreModal: boolean;
  showCRMLearnMoreModal: boolean;
  isLoadingRemovingShop: boolean;
  dataRetrieveError?: string;
  alertTitle?: string;
  alertMessage?: string | JSX.Element;
  shopName?: string;
  shopId?: string;
  confirmationCode: string;
  tabset: ActivityType;
  crmTabset: CrmActivityType;
  timeframe: string;
  inviteResponseLoading: boolean;
  loadingResponse: string;
  oemRegions: [];
  shopAppointments: [] | null;
  shopOrders: [];
}

type InputType = 'confirmationCode';

const MY_ACTIVITY: Record<string, ActivityType> = {
  SHOP: 'SHOP_ACTIONS',
  TOOL: 'TOOL_ACTIONS',
};

const MY_CRM_ACTIVITY: Record<string, CrmActivityType> = {
  APPOINTMENT: 'APPOINTMENT_ACTIONS',
  ORDER: 'ORDER_ACTIONS',
};

class Home extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      isLoading: true,
      showModal: false,
      emailVerificationCodeSent: false,
      windowWidth: window.innerWidth,
      rowsText: TextOnly('rows'),
      showConfirmModal: false,
      showPlusLearnMoreModal: false,
      showCRMLearnMoreModal: false,
      isLoadingRemovingShop: false,
      inviteResponseLoading: false,
      tabset: MY_ACTIVITY.SHOP,
      crmTabset: MY_CRM_ACTIVITY.APPOINTMENT,
      timeframe: 'last7Days',
      loadingResponse: '',
      confirmationCode: '',
      oemRegions: [],
      shopAppointments: null,
      shopOrders: [],
    };
  }

  async componentDidUpdate(prevProps: HomeProps) {
    if (this.props.lang !== prevProps.lang) {
      this.setState({ rowsText: TextOnly('rows') });
    }
    if (this.props.userContext?.currentShop?.crmState === 'ACTIVE' && this.state.shopAppointments === null) {
      const self = this;
      await Promise.all([ // Wait for all functions to be completed
        this.getShopEvents(this.props.userContext?.currentShop?.shopID, self),
        this.getShopOrders(this.props.userContext?.currentShop?.shopID, self),
      ]);  // Run functions concurrently
    }
  }

  async componentDidMount() {
    window.addEventListener('resize', this.setWindowWidth.bind(this));

    if (this.props.userContext?.currentShop?.shopType === 'PLUS' &&
        config.CRM_STATE === 'ACTIVE' &&
        this.props.user.shopUserState === 'ACTIVE' &&
        this.props.userContext?.currentShop?.crmState === 'ACTIVE') {

      const self = this;
      this.setState({ shopAppointments: [] });
      await Promise.all([ // Wait for all functions to be completed
        this.getShopEvents(this.props.userContext?.currentShop?.shopID, self),
        this.getShopOrders(this.props.userContext?.currentShop?.shopID, self),
      ]);  // Run functions concurrently

      this.setState({
        isLoading: false,
      });
    }

    this.setState({
      oemRegions: await getOEMRegionList(),
    });
  }

  async getShopOrders(shopID: string, self: any) {
    const orderResponse = await crmGetShopOrders(shopID);
    if (!orderResponse?.error) {
      self.setState({
        shopOrders: orderResponse,
      });
    } else {
      toast.error(`${TextOnly('error')}: ${orderResponse.error}`, {
        containerId: 'standard',
      });
    }

  }

  async getShopEvents(shopID: string, self: any) {
    this.setState({ shopAppointments: [] });
    const response = await crmGetShopEvents(shopID);
    let shopAppointments = [];
    if (!response?.error) {
      const currentFullDate = new Date();
      const startOfCurrentWeek = new Date(
        currentFullDate.getFullYear(),
        currentFullDate.getMonth(),
        currentFullDate.getDate() - currentFullDate.getDay(), // Start of the week (Sunday)
      );
      shopAppointments = response?.filter((event: any) => {
        return event.eventType === "APPOINTMENT" && (new Date(event.endDate)) > startOfCurrentWeek;
      });
      const appointments = shopAppointments.map((appt: any) => {
        if (appt.order) {
          appt.order.displayOrderNumber = convertOrderNumToDisplay(appt.order.orderNumber);
        }
        return appt;
      })
      let newStates: any = {
        shopAppointments: appointments,
      }
      if (appointments.length === 0) {
        newStates.crmTabset = MY_CRM_ACTIVITY.ORDER;
      }
      self.setState(newStates);
    } else {
      toast.error(`${TextOnly('error')}: ${response.error}`, {
        containerId: 'standard',
      });
    }
  }

  setWindowWidth() {
    setTimeout(this.setStateWidth.bind(this), 20);
  }

  setStateWidth() {
    this.setState({ windowWidth: window.outerWidth });
  }

  handleDeleteButton(shopName: string, shopId: string) {
    this.setState({
      shopName: shopName,
      shopId: shopId,
      showConfirmModal: true,
    });
  }

  async handleConfirmModal() {
    // Handle delete of user
    this.setState({ isLoadingRemovingShop: true });
    let result = await removeUserFromShop(
      this.state.shopId,
      this.props.user.userID
    );
    if (!result?.error && this.state.shopId) {
      this.props.fetchUser();
      this.setState({ showConfirmModal: false, isLoadingRemovingShop: false });
      toast.success(TextOnly('removeFromShop'), { containerId: 'standard' });
    } else {
      this.setState({
        alertTitle: TextOnly('error'),
        alertMessage: result?.error ? result.error : 'No Shop found',
        showConfirmModal: false,
        isLoadingRemovingShop: false,
        showModal: true,
      });
    }
  }

  handleShowPlusLearnMoreModal = () => {
    this.setState ({ showPlusLearnMoreModal: true });
  }

  handleShowCRMLearnMoreModal = () => {
    this.setState({ showCRMLearnMoreModal: true });
  }

  handleCancelModal() {
    this.setState({ showConfirmModal: false, shopName: '', shopId: undefined });
  }

  handleCancelLearnMoreModal = () => {
    const modalSlider = document.querySelector(
      this.state.windowWidth < 753
        ? '.c-modal-slider-90'
        : '.c-modal-slider-75'
    );
    if (modalSlider) {
      modalSlider?.classList.add('closed');
    }
    document.querySelector('.c-modal')?.classList.add('closed');
    setTimeout(() => {
      this.setState({
        showPlusLearnMoreModal: false,
        showCRMLearnMoreModal: false,
      })
    }, 350);
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.id as keyof Pick<HomeState, InputType>;

    const reg = new RegExp(/^(\s{0}|\d+)$/);
    if (
      event.target.value.length < 11 &&
      reg.test(event.target.value) === true
    ) {
      this.setState({
        [key]: event.target.value,
      });
    }
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  async handleAcceptShopRequest(shop: Shop, response: string) {
    this.setState({ inviteResponseLoading: true, loadingResponse: response });

    try {
      await respondToShopInvitation(shop.shopID, response);
      if (response === 'ACCEPT') {
        toast.success(TextOnly('acceptedInviteSuccess'), {
          containerId: 'standard',
        });
        this.props.fetchUser();
      }
      if (response === 'REJECT') {
        toast.error(TextOnly('rejectedInviteSuccess'), {
          containerId: 'standard',
          autoClose: false,
        });
        this.props.fetchUser();
      }
    } catch (e) {
      console.log('Error responding to shop invitation: ', e);
      alert(e);
    } finally {
      this.setState({ inviteResponseLoading: false, loadingResponse: '' });
    }
  }

  handleServiceCenterCreate() {
    this.props.history.push('/shopSignup');
  }

  handleAddTools() {
    this.props.history.push('/manageTools/manageShopTools');
  }

  handleAddUsers() {
    this.props.history.push('/manageUsers/currentUsers');
  }

  handleContactUs() {
    window.location.href = 'https://info.autoauth.com/contact/#faq_section';
  }

  handleOpenAlertModal = ({
    title,
    message,
  }: {
    title: string;
    message: string | JSX.Element;
  }) => {
    this.setState({
      alertTitle: title,
      alertMessage: message,
      showModal: true,
    });
  };

  handleValidateEmail = () => {
    this.setState({
      isLoading: true,
    });
    sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
      .then(() => {
        this.setState({
          isLoading: false,
          emailVerificationCodeSent: true,
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage: e.message,
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleSubmitConfirmationCode = () => {
    if (this.state.confirmationCode === undefined) {
      throw new Error('Confirmation code is no set.');
    }

    this.setState({
      isLoading: true,
    });

    confirmUserAttribute({
      userAttributeKey: 'email',
      confirmationCode: this.state.confirmationCode.trim()
    })
      .then(async () => {
        await this.props.fetchUser();
        this.setState({
          emailVerificationCodeSent: false,
          alertMessage: TextOnly('emailAddressVerified'),
          showModal: true,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({
          alertMessage: TextOnly('invalidVerifyCode'),
          showModal: true,
          isLoading: false,
        });
      });
  };

  handleRouteToPlus = () => {
    this.props.history.push(`/upgradeToPlus/comparePlans`);
  };

  handleTabsetMyActivity = (activityType: ActivityType) => {
    this.setState({
      tabset: activityType,
    });
  };

  handleTabsetCrmMyActivity = (CRMActivityType: CrmActivityType) => {
    this.setState({
      crmTabset: CRMActivityType,
    });
  };

  handleChangeTimeFrame = (event: any) => {
    event.preventDefault();
    this.setState({
      timeframe: event.target.value,
    });
  };

  renderLander() {
    const { tabset } = this.state;
    const shops = this.props?.user?.shops
      ? this.props.user.shops.map((shop) => {
          let shopState = 'ACTIVE';
          switch (shop.shopSubscriptionState) {
            case 'TERMINATED':
              shopState = 'SUSPENDED';
              break;
            case 'VALID':
              shopState = 'ACTIVE';
              break;
            case 'PAST_DUE':
              shopState = 'PAYMENT PAST DUE';
              break;
            default:
              shopState = shop.shopSubscriptionState;
          }
          return {
            shopID: shop.shopID,
            name: shop.shopName,
            role: shop.shopUserRole,
            state: shop.shopUserState,
            shopState: shopState,
          };
        })
      : [];

    const isShopOwner =
      shops.findIndex((shop) => {
        return shop.role === SHOP_ROLES.OWNER;
      }) !== -1;
    const ownerShop = isShopOwner
      ? this.props.user.shops?.find(
          (shop) => shop.shopUserRole === SHOP_ROLES.OWNER
        )
      : null;

    const pendingShops = this.props?.user?.shops
      ? this.props.user.shops.filter((shop) => {
          return shop.shopUserState === SHOP_STATES.PENDING;
        })
      : [];
    const paidThruDate = this.props?.user?.shopPaymentInfo
      ? new Date(this.props.user.shopPaymentInfo.paidThroughDate).getTime()
      : 0;
    const pastDue =
      this.props?.user?.paymentState === PAYMENT_STATES.PAST_DUE ||
      this.props?.user?.paymentState === PAYMENT_STATES.TERMINATED;
    const today = new Date().getTime();
    const expired = paidThruDate - today < 0;

    if (!this.props.user) {
      return <Loading />;
    }

    const { isOwner, isAdmin, isTech, isEnterprise, isPlus, isStandard } =
      this.props.userContext;
    const currentShop = this.props.userContext?.currentShop;
    const shopUsers: User[] = currentShop?.shopUsers
      ? currentShopUsersFromProps(
        currentShop.shopUsers,
      ) as User[]
      : [];

    if (this.state.tabset !== MY_ACTIVITY.TOOL && isTech) {
      this.setState({
        tabset: MY_ACTIVITY.TOOL
      })
    }

    const timeframeOptions = ['Today', 'last7Days', 'last30Days'].map((key) => {
      return (
        <option key={key} value={key}>
          {TextOnly(key as LangDictKey)}
        </option>
      );
    });

    return (
      <div className="l-container">
        <Header title={TextOnly('welcome')} />
        {currentShop ? (
          <div className="c-box shadow">
            <div className="l-flex-between">
              <section className="c-section-alt">
                <div className="c-section-alt__content">
                  <label className="c-section-alt__label">
                    <Text tid="shop" />
                  </label>
                  <span className="c-section-alt__value">
                    {currentShop?.shopName}
                  </span>
                </div>
                <div className="c-section-alt__content">
                  <label className="c-section-alt__label">
                    <Text tid="myRole" />
                  </label>
                  <span className="c-section-alt__value">
                    {TextOnly(currentShop?.shopUserRole)}
                  </span>
                </div>
                {currentShop?.shopUserState !== 'ACTIVE' && (
                  <div className="c-section-alt__content">
                    <label className="c-section-alt__label">
                      <Text tid="myStatus" />
                    </label>
                    <span className="c-section-alt__value">
                      <Led status={currentShop?.shopUserState} />
                    </span>
                  </div>
                )}
              </section>
              {isTech || isAdmin ? (
                <button
                  className="c-btn"
                  onClick={this.handleDeleteButton.bind(
                    this,
                    currentShop?.shopName,
                    currentShop?.shopID
                  )}
                >
                  <div className="c-btn__inner">
                    <Text tid="leaveShop" />
                  </div>
                </button>
              ) : null}
            </div>
          </div>
        ) : null}


      {ownerShop?.shopState === SHOP_STATES.CHARGEBACKRECEIVED ||
        ((ownerShop?.shopState === SHOP_STATES.SUSPENDED || ownerShop?.shopState === "TERMINATED") &&
          ownerShop?.suspendReason === 'DISPUTE_LOST') ? (
          <div className="c-notice c-notice--column action-needed">
            <div className="c-notice__content">
              <h2 className="c-notice__title c-field__error">
                <Text tid="NOTICE" />
              </h2>
              <p className="c-notice__text">
                { TextOnly('chargebackLost', { state: ownerShop.shopState }) }
              </p>
              {ownerShop.shopState === SHOP_STATES.CHARGEBACKRECEIVED ? (
                <p className="c-notice__text u-margin-top">
                  <Text tid="howToResolveChargeback" />.
                </p>
              ) : (
                ''
              )}
            </div>
            <div className="c-notice__buttons">
              <Link className="c-btn red-btn" to="/shopProfile">
                { TextOnly('shopProfile') }
              </Link>
            </div>
          </div>
        ) : null}

        {(!pastDue &&
          this.props.user.language === 'en' &&
          !!ownerShop?.userShopTools?.length &&
          (this.props.currentShop.country === "CA" || this.props.currentShop.country === "US")) &&
            <PromoBox link={'https://sodiumhalogen.com/aa-interview'} />
        }

        {!this.props.user.emailVerified &&
          (!this.state.emailVerificationCodeSent ? (
            <div className="c-notice  c-notice--column">
              <div className="c-notice__content">
                <h2 className="c-notice__title">
                  <Text tid="validateEmail" />
                </h2>
                <p className="c-notice__text">
                  <Text tid="userStatePending" />{' '}
                  <Text
                    tid="mustValidateEmailMessage"
                    sub={{
                      email: <span>{this.props.user.email}</span>,
                    }}
                  />
                </p>
              </div>
              <div className="c-notice__buttons">
                <LoaderButton
                  text={TextOnly('validateEmail')}
                  id="validate-email"
                  disabled={this.state.emailVerificationCodeSent}
                  className="c-btn"
                  onClick={this.handleValidateEmail}
                  isLoading={this.state.isLoading}
                  loadingText={TextOnly('sendingCode')}
                />
              </div>
            </div>
          ) : (
            <div className="c-notice c-notice--column">
              <div className="c-notice__content">
                <h2 className="c-notice__title">
                  <Text tid="validateEmail" />
                </h2>
                <p className="c-notice__text">
                  <Text tid="emailValidationSentTo" />
                  <br />
                  {this.props.user.email}
                  <br />
                  <br />
                  <Text tid="enterCodeToConfirmEmail" />
                </p>
              </div>
              <div>
                <div className="c-field">
                  <input
                    autoFocus
                    className="c-input"
                    maxLength={10}
                    id="confirmationCode"
                    value={this.state.confirmationCode}
                    placeholder={TextOnly('confirmationCode')}
                    type="text"
                    onChange={this.handleChange}
                    disabled={this.state.isLoading}
                  />
                </div>
                <LoaderButton
                  text={TextOnly('submitConfirmationCode')}
                  id="validate-email"
                  className="c-btn"
                  disabled={
                    !this.state.emailVerificationCodeSent ||
                    this.state.confirmationCode?.length < 6
                  }
                  type="button"
                  onClick={this.handleSubmitConfirmationCode}
                  isLoading={this.state.isLoading}
                  loadingText={TextOnly('submitting')}
                />
              </div>
            </div>
          ))
        }

        {this.props?.user?.shopPaymentInfo?.status === 'Canceled' &&
        !isEnterprise ? (
          <div className="c-notice">
            <div className="c-notice__content">
              <h2 className="c-notice__title">
                <span className="c-field__error">
                  <Text tid="NOTICE" />
                </span>
              </h2>

              {expired ? (
                <div className="c-notice__text">
                  <div>
                    <Text tid="shopDoesNotHaveFormOfPayment" />
                  </div>{' '}
                  <Text
                    tid="mustAddPaymentShopExpired"
                    sub={{
                      date: (
                        <span>
                          {this.props.user.shopPaymentInfo.paidThroughDate}
                        </span>
                      ),
                    }}
                  />
                </div>
              ) : (
                <div className="c-notice__text">
                  <div>
                    <Text tid="shopDoesNotHaveFormOfPayment" />
                  </div>{' '}
                  <Text
                    tid="mustAddPaymentShopWillExpire"
                    sub={{
                      date: (
                        <span className="c-field__error">
                          {this.props.user.shopPaymentInfo.paidThroughDate}
                        </span>
                      ),
                    }}
                  />
                </div>
              )}
            </div>
            <div className="c-notice__buttons">
              <Link className="c-btn" to="/shopProfile">
                <div className="c-btn__inner">
                  <Text tid="shopProfile" />
                </div>
              </Link>
            </div>
          </div>
        ) : null}

        {pendingShops.map((shop, index) => (
          <div className="c-notice" key={index}>
            <div className="c-notice__content">
              <h2 className="c-notice__title">
                <Text tid="NOTICE" />
              </h2>
              <p className="c-notice__text">
                <Text tid="youHaveBeenInvited" /> <span>{shop.shopName}</span>
              </p>
            </div>
            <div className="c-notice__buttons">
              <LoaderButton
                type="button"
                className="c-btn u-margin-right-large"
                isLoading={
                  this.state.inviteResponseLoading &&
                  this.state.loadingResponse === 'ACCEPT'
                }
                disabled={this.state.inviteResponseLoading}
                text={TextOnly('clickAccept')}
                loadingText={TextOnly('clickAccept')}
                onClick={this.handleAcceptShopRequest.bind(
                  this,
                  shop,
                  'ACCEPT'
                )}
              />
              <LoaderButton
                type="button"
                className="c-btn-outline"
                isLoading={
                  this.state.inviteResponseLoading &&
                  this.state.loadingResponse === 'REJECT'
                }
                disabled={this.state.inviteResponseLoading}
                text={TextOnly('clickReject')}
                loadingText={TextOnly('clickReject')}
                onClick={this.handleAcceptShopRequest.bind(
                  this,
                  shop,
                  'REJECT'
                )}
              />
            </div>
          </div>
        ))}

        {!pastDue && ownerShop?.userShopTools?.length === 0 ? (
          <div className="c-notice">
            <div className="c-notice__content">
              <h2 className="c-notice__title">
                <Text tid="getStarted" />
              </h2>
              <p className="c-notice__text">
                <Text tid="addToolsAndUsers" />
              </p>
            </div>
            <div className="c-notice__buttons">
              <button
                className="c-btn u-margin-right-large"
                onClick={this.handleAddUsers.bind(this)}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="addUser" />
                </div>
              </button>
              <button
                className="c-btn"
                onClick={this.handleAddTools.bind(this)}
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="addTools" />
                </div>
              </button>
            </div>
          </div>
        ) : null}

        {shops.length === 0 ? (
          <div className="l-container-center l-flex-column">
            <div className="l-container-med c-box u-margin-bottom-large">
              <h2>
                <Text tid="forServiceCenters" />
              </h2>
              <p>
                <Text tid="serviceCenterSignupMessage" />
              </p>
              <Link
                onClick={this.handleServiceCenterCreate.bind(this)}
                className="c-btn u-margin-top-large u-margin-bottom-large"
                to="#"
              >
                <div className="c-btn__inner">
                  <Text tid="serviceCenterSignupLink" />
                </div>
              </Link>
            </div>
            <div className="l-container-med c-box u-margin-top-large">
              <h2>
                <Text tid="forTechnicians" />
              </h2>
              <p>
                <Text
                  tid="employeeSignupMessage"
                  sub={{ userProfile: <Text tid="userProfile" /> }}
                />
              </p>

              <Link
                to="userProfile"
                className="c-btn u-margin-top-large u-margin-bottom-large"
              >
                <div className="c-btn__inner">
                  <Text tid="userProfile" />
                </div>
              </Link>
            </div>
          </div>
        ) : null}

        {/* STANDARD/OWNER or ADMIN with a shop */}
        {isStandard && (isOwner || isAdmin) && !!shops.length ? (
          <div className="c-box c-box--promo-plus">
            <section className={"c-section-alt"}>
              <div className="c-box__title">
                <div className="h2 text-bold c-box__title">
                  <Text tid="upgradeToStandardPlus" />
                </div>
              </div>
            </section>
            <section className={"c-section-alt"}>
              <div className="c-notice__text">
                <Text tid="upgradeToStandardPlusDescriptionAlt" />
              </div>
            </section>
            <section className={"c-section-alt"}>
              <button
                className="c-btn-dark"
                onClick={this.handleRouteToPlus}
              >
                <Text tid="upgradeNow" />
              </button>
            </section>
          </div>
        ) : null}

        {/* STANDARD/TECH with a shop */}
        {isStandard && isTech && !!shops.length ? (
          <>
            <div className="c-box c-box--promo-plus">
              <div className="c-box__title">
                <Text tid="techUpgradeToStandardPlus" />
              </div>
              <Text tid="techUpgradeToStandardPlusDescription" />
              <button
                className="c-btn-dark"
                onClick={this.handleShowPlusLearnMoreModal}
              >
                <Text tid="learnMore" />
              </button>
            </div>
            <div className="c-box c-box--promo-plus-crm">
              <div className="c-box__title">
                <Text tid="techUpgradeToStandardPlusCRM" />
              </div>
              <Text tid="homePageCrmNoticeExplain" />
              <button
                className="c-btn-dark-red"
                onClick={this.handleShowCRMLearnMoreModal}
              >
                <Text tid="learnMore" />
              </button>
            </div>
          </>
        ) : null}

        {(isPlus || isStandard) && (isOwner || isAdmin) && currentShop && currentShop?.crmState !== 'ACTIVE' ? (
          <div className="c-box c-box--promo-plus-crm">
            <div className="c-box__title">
              <Text tid="upgradeToAutoAuthPlusCrm" />
            </div>
            <Text tid='homePageCrmNoticeExplain' />
            <button
              className="c-btn-dark-red"
              onClick={this.handleRouteToPlus}
            >
              <Text tid="upgradeNow" />
            </button>
          </div>
        ) : null}

        { currentShop && (isPlus || isEnterprise) && this.props.user.shopUserState === 'ACTIVE' ?
          <div className={"c-box--white u-padding-none"}>
            <div className={'u-width-100-percent l-flex-row l-flex-gap u-flex-expand'}>
              { config.CRM_STATE !== "ACTIVE" || currentShop.crmState !== 'ACTIVE' || this.state.shopAppointments !== null ?
              <Tabs defaultActiveKey={config.CRM_STATE === 'ACTIVE' && currentShop.crmState === 'ACTIVE' ? 'eventsList' : 'myShopActivity'}>
                { config.CRM_STATE === 'ACTIVE' && currentShop.crmState === 'ACTIVE' ?
                  <Tab eventKey={'eventsList'} label={TextOnly("upcomingAppointments")} className="tab-my-activity">
                    <EventsList shopAppointments={this.state.shopAppointments} />
                  </Tab>
                : null }
                { config.CRM_STATE === 'ACTIVE' && currentShop.crmState === 'ACTIVE' ?
                  <Tab eventKey={'orderStatus'}label={TextOnly('orderStatus')} className="tab-my-activity">
                    <OrderStatus shopOrders={this.state.shopOrders} />
                  </Tab>
                : null }
                <Tab eventKey={'myShopActivity'} label={TextOnly("myShopActivity")} className="tab-my-activity">
                  <>
                    <div className={'u-padding-bottom-med u-max-width-205px'}>
                      <div className="c-select">
                        <select
                          id="timeframe"
                          onChange={this.handleChangeTimeFrame}
                          value={this.state.timeframe || TextOnly('last7Days')}
                          className='u-text-capitalize'
                        >
                          {timeframeOptions}
                        </select>
                      </div>
                    </div>
                    <MyShopActionsTable
                      shop={currentShop}
                      user={this.props.user}
                      shopUsers={shopUsers}
                      timeframe={this.state.timeframe}
                      oemRegions={this.state.oemRegions}
                    />
                  </>
                </Tab>
                <Tab eventKey={'myToolActivity'} label={TextOnly("myToolActivity")} className="tab-my-activity">
                  <>
                    <div className={'u-padding-bottom-med u-max-width-205px'}>
                      <div className="c-select">
                        <select
                          id="timeframe"
                          onChange={this.handleChangeTimeFrame}
                          value={this.state.timeframe || TextOnly('last7Days')}
                          className='u-text-capitalize'
                        >
                          {timeframeOptions}
                        </select>
                      </div>
                    </div>
                    <MyToolActionsTable
                    shopId={currentShop.shopID}
                    userId={this.props.user.userID}
                    userFullName={`${this.props.user.firstName} ${this.props.user.lastName}`}
                    shopUserRole={currentShop.shopUserRole}
                    openAlertModal={this.handleOpenAlertModal}
                    timeframe={this.state.timeframe}
                    />
                  </>
                </Tab>
              </Tabs>
              : <Loading />
              }
            </div>
          </div>
        : ''}

        <AlertModal
          title={this.state.alertTitle}
          message={this.state.alertMessage}
          showModal={this.state.showModal}
          handleCancel={this.handleCancel.bind(this)}
        />

        <Dialog
          isOpen={this.state.showConfirmModal}
          onDismiss={this.handleCancelModal.bind(this)}
          className="c-modal"
          aria-label={TextOnly('confirmRemovalFromShop')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="confirmRemovalFromShop" />
          </h1>

          <div className="c-modal__body">
            <p>
              <Text tid="areYouSureYouWantToRemoveYourselfFrom" />{' '}
              {this.state.shopName}?
            </p>
            <LoaderButton
              className="c-btn"
              type="button"
              text={TextOnly('confirm')}
              loadingText={TextOnly('removing')}
              isLoading={this.state.isLoadingRemovingShop}
              onClick={this.handleConfirmModal.bind(this)}
            />{' '}
            <button
              className="c-btn-outline"
              onClick={this.handleCancelModal.bind(this)}
            >
              <Text tid="cancel" />
            </button>
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showPlusLearnMoreModal}
          onDismiss={this.handleCancelLearnMoreModal.bind(this)}
          className={
            this.state.windowWidth < 753
              ? 'c-modal-slider-90'
              : 'c-modal-slider-75'
          }
          aria-label={TextOnly('learnMore')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelLearnMoreModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="standardPlusFeatures" />
          </h1>

          <div className="c-modal__body">
            <StatsAndTrendsPromo />
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.showCRMLearnMoreModal}
          onDismiss={this.handleCancelLearnMoreModal.bind(this)}
          className={
            this.state.windowWidth < 753
              ? 'c-modal-slider-90'
              : 'c-modal-slider-75'
          }
          aria-label={TextOnly('learnMore')}
        >
          <button
            className="c-btn-icon c-modal__close"
            onClick={this.handleCancelLearnMoreModal.bind(this)}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fal fa-times" />
            </div>
          </button>
          <h1 className="c-modal__heading">
            <Text tid="plusCrmFeatures" />
          </h1>

          <div className="c-modal__body">
            <CrmPromoContent promoType='calendar' />
            <CrmPromoContent promoType='manageOrders' />
            <CrmPromoContent promoType='manageCustomers' />
            <CrmPromoContent promoType='manageVehicles' />
          </div>
        </Dialog>

        <br/>
      </div>
    );
  }

  render() {
    return <>{this.renderLander()}</>;
  }
}

export default (props: ChildProps) => {
  const userContext = useUser();
  return <Home userContext={userContext} {...props} />;
};
