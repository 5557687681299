import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ReactTable, { Column } from 'react-table';
import { useQuery } from 'react-query';
import { Text, TextOnly } from '../../components/Text';
import { TOOL_EVENTS } from '../../CONSTANTS';
import { getSelfActivityLog, getVINDetails } from '../../libs/db-lib';
import { formatDateTime2Lines, pipe } from '../../libs/utils';
import { getSavedColumns } from '../../libs/utils-ts';
import { Action, IError, ShopRole } from '../../types';
import _ from 'underscore';

interface MyToolActionsTableProps {
  shopId: string;
  userId: string;
  userFullName: string;
  shopUserRole: ShopRole;
  openAlertModal: (arg0: {
    title: string;
    message: string | JSX.Element;
  }) => void;
  timeframe: string;
}

interface ShopID {
  shopID: string;
}

interface DateTime {
  date: string;
  time: string;
}

interface IVINDetails {
  [key: string]: string;
}

export const MyToolActionsTable = ({
  shopId,
  userId,
  userFullName,
  shopUserRole,
  openAlertModal,
  timeframe,
}: MyToolActionsTableProps) => {
  const savedColumnSizes = getSavedColumns('myShopActionColumns');
  let startDate = moment().subtract(7, 'd').endOf('day').utc().toISOString();
  let endDate = moment().endOf('day').utc().toISOString();

  switch (timeframe) {
    case 'last30Days':
      startDate = moment().subtract(30, 'd').endOf('day').utc().toISOString();
      endDate = moment().endOf('day').utc().toISOString();
      break;
    case 'Today':
      startDate = moment().startOf('day').utc().toISOString();
      endDate = moment().endOf('day').utc().toISOString();
      break;
    case 'last7Days':
    default:
      startDate = moment().subtract(7, 'd').endOf('day').utc().toISOString();
      endDate = moment().endOf('day').utc().toISOString();
      break;
  }

  const { isLoading, error, data } = useQuery<any, IError, any>(
    `getSelfActivityLog-${startDate}-${endDate}`,
    () => getSelfActivityLog(userId, startDate, endDate)
  );

  const [VINDetails, setVinsDetails] = useState<any>([]);
  const [VINsLoaded, setVINsLoaded] = useState<any>(false);

  useEffect(() => {
    async function fetchVinDetails() {
      const vins = data?.logEntries
        ? data.logEntries
            .filter((log: Action) => log.vin)
            .map((log: Action) => log.vin)
        : [];
      if (vins.length > 0) {
        const uniqueVins = _.uniq(vins);
        const results = await getVINDetails(shopId, uniqueVins);
        setVinsDetails(!results.error ? results : []);
        setVINsLoaded(true);
      } else {
        setVINsLoaded(true);
      }
    }
    fetchVinDetails();
  }, [data, userId, shopId]);

  const actionsColumnDefs: Column[] = data
    ? [
        {
          Header: <Text tid="date" />,
          id: 'actionDate',
          accessor: 'actionDate',
          headerClassName: 'hide-second',
          className: 'hide-second',
          maxWidth: 175,
          sortMethod: (a, b, desc) => {
            const externalAStr = new Date(a);
            const externalBStr = new Date(b);
            if (desc) {
              if (externalAStr > externalBStr) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (externalAStr < externalBStr) {
                return -1;
              } else {
                return 1;
              }
            }
          },
          Cell: (row) => {
            const dateObj: DateTime = formatDateTime2Lines(row.original.actionDate);
            return (
              <div>
                {dateObj.date}
                <br />
                {dateObj.time}
              </div>
            );
          },
          minWidth: savedColumnSizes?.actionDate || 200,
        },
        {
          Header: <Text tid="toolIsRegistered" />,
          id: 'shopID',
          maxWidth: 150,
          accessor: (id) => {
            return id.shopID ? (
              <span className="u-text-center u-width-100-percent">
                <i className="fal fa-check-circle" title="fa-check-circle" />
              </span>
            ) : (
              <span className="u-text-error u-text-center u-width-100-percent">
                <i className="fal fa-times-circle" title="fa-times-circle" />
              </span>
            );
          },
          minWidth: savedColumnSizes?.name || 200,
        },
        {
          Header: <Text tid="action" />,
          accessor: 'actionMessage',
          minWidth: savedColumnSizes?.actionMessage || 200,
          Cell: (row) => {
            if (row.original.isError) {
              return (
                <div>
                  <span className="u-text-error">
                    {row.original.actionMessage ?? row.original.actionCode}
                  </span>
                  <br />
                  <span className="u-text-error">
                    {row.original.apiErrorMessage}
                  </span>
                </div>
              );
            } else {

              return <div>{row.original.actionMessage ?? row.original.actionCode}</div>;
            }
          },
        },
        {
          Header: <Text tid="toolSerialNumber" />,
          accessor: 'toolSerial',
          minWidth: savedColumnSizes.toolSerial || 150,
          Cell: (row) => {
            const { toolManufacturer, toolModel, toolSerial } = row.original;
            return (
              <span>
                <span>
                  {toolManufacturer} {toolModel}
                </span>
                <br />
                <span>{toolSerial}</span>
              </span>
            );
          },
        },
        {
          Header: <Text tid="vin" />,
          id: 'vin',
          accessor: (row) => {
            if (row.apiErrorMessage?.includes('VIN is invalid')) {
              return row.vin;
            } else {
              const details = VINDetails
                ? VINDetails?.find((rec: IVINDetails) => rec.vin === row.vin)
                : {};
              return (
                <span>
                  <span>
                    {details
                      ? details.Year + ' ' + details.Make + ' ' + details.Model
                      : ''}
                  </span>
                  <br />
                  <span>{row.vin}</span>
                </span>
              );
            }
          },
          minWidth: savedColumnSizes.vin || 200,
        },
      ]
    : [];

  if (isLoading || !VINsLoaded)
    return (
      <>
        <i className="fal fa-spinner-third spinning u-margin-right-small" />
        <Text tid="loading" /> <Text tid="userActions" />
        ...
      </>
    );
  if (error) return <p>{error.message}</p>;
  if (data?.error)
    return (
      <p className="error">
        <Text tid="error" />: {data.error}
      </p>
    );

  const addActionProperties = (actions: Action[]) =>
    actions.map((action: Action) => ({
      ...action,
      name: userFullName,
      shopRole: shopUserRole,
    }));

  const filterForToolActions = (actions: Action[]) =>
    actions.filter(
      (action) =>
        TOOL_EVENTS.includes(action.actionCode) &&
        (!action.shopID || action.shopID === shopId)
    );

  const filteredShopActions = data?.logEntries
    ? pipe(filterForToolActions, addActionProperties)(data?.logEntries)
    : [];

  return (
    <>
      <ReactTable
        columns={actionsColumnDefs}
        data={filteredShopActions}
        className="-highlight"
        showPaginationTop={true}
        previousText={TextOnly('previousPage')}
        nextText={TextOnly('nextPage')}
        pageText={TextOnly('page')}
        ofText={TextOnly('of')}
        rowsText={TextOnly('rows')}
        noDataText={TextOnly('noToolActions')}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'actionDate',
            desc: true,
          },
        ]}
        onResizedChange={(a) =>
          localStorage.setItem('shopActionsColumns', JSON.stringify(a))
        }
      />
    </>
  );
};
